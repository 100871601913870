@use "sass:math";

$navForegroundColor: $body-color;

.oc-nav-trigger{
  cursor: pointer;
}

.nav-backdrop-layer{
  z-index: 30;
  width: 300%;
  height: 100%;
  opacity: 0;
  position: fixed;
  left: -300%;
  top: 0;
  transition: all .9s ease;
}


html.nav-on{

  overflow: hidden !important;
  max-height: 100% !important;
  body{
    overflow: hidden !important;
    max-height: 100% !important;
  }

  .mona-nav{
      position: fixed;
      top: 0;
      left: 0;
      opacity: 1;
      height: 100%;
      @include media-breakpoint-down(md){
        height: 100vh;
      }
      @media only screen and (max-height: 800px) {
        height: 100vh;
      }
      transition: left ease .6s, opacity .3s linear, height ease .6s;

      @include media-breakpoint-down(md){
        transition: left ease .2s, opacity .2s linear, height ease .2s;
      }
    }

  .nav-backdrop-layer{
    transition: opacity ease 2s;
    opacity: 1;
    left: 0;
    background: rgba(0,0,0,0.5);
  }
}


.mona-nav{

  width: auto;
  min-width: 380px;
  background-color: $brand-gray-dark;
  height: 1px;
  overflow-y: hidden;
  opacity: 1;
  position: absolute;
  top: 0;
  z-index: 50;
  left: -130vw;
  @include media-breakpoint-down(md){
    left: -1000vw;
  }
  @media only screen and (min-height: 801px) {
    height: 100%;
  }
  transition: left ease 1.2s, height ease .6s;

  ul{
    margin: 0;
    list-style: none;
  }

  ul.mona-nav-list-main{
    max-height: 100%;
    display: block;
    overflow-y: scroll;
    padding: 60px 0 40px 100px;
    @include media-breakpoint-down(md){
      padding: 80px 0 40px 0;
    }
    background: transparent;
  }

  ul.mona-nav-list-main li{
    background: transparent;
    list-style: none;
  }

  ul.mona-nav-list-main > li{
    //border-bottom: 3px solid #fff;
    padding-left: 5px;
    position: relative;
    margin-bottom: 15px;
  }

  //ul.mona-nav-list-main > li.active:before{
  //  content: "";
  //  width: 5px;
  //  height: 100%;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background-color: #E83460;
  //}

  ul.mona-nav-list-main ul > li.active{
    position: relative;
  }
  //ul.mona-nav-list-main ul > li.active a:before{
  //  content: "";
  //  width: 10px;
  //  height: 10px;
  //  position: absolute;
  //  top: 9px;
  //  left: -2px;
  //  background-color: #E83460;
  //  border-radius: 5px;
  //}

  ul.mona-nav-list-main li a{
    font-weight: $font-weight-bold;
    display: block;
    text-decoration: none;
    font-size: 16px;
    padding: 4px 15px;
    color: $navForegroundColor;
  }

  ul.mona-nav-list-main > li ul li{
    position: relative;
    &:before{
      content: "›";
      position: absolute;
      left: -9px;
      top: -3px;
      color: $navForegroundColor;
      font-size: 22px;
    }
  }
  ul.mona-nav-list-main > li > a{
    margin-left: 26px;
  }

  ul.mona-nav-list-main li > ul{
    margin-left: 26px;
    padding-bottom: 4px;
  }
  ul.mona-nav-list-main li > ul li ul{
    margin-top: 10px;
    margin-left: -5px;
    padding-bottom: 4px;
  }
  ul.mona-nav-list-main li > ul li.active > ul{
    margin-top: 0;
  }

  ul.mona-nav-list-main li > ul li a{
    font-weight: $font-weight-normal;
    font-size: 14px;
    padding: 4px 15px;
  }

  ul.mona-nav-list-main > li.active{
    position: relative;

    >a{
      .text-wrapper{
        color: $primary;
        position: relative;
        display: inline-block;
        border-radius: 2px;
        overflow: hidden;
        border: 5px solid $navForegroundColor;
        padding-right: 5px;
        margin-left: -5px;
        padding-left: 5px;
        &:before{
          position: absolute;
          z-index: -1;
          background: $navForegroundColor;
          width: 99%;
          height: 99%;
          content: "";
          margin-left: -5px;
          outline: 4px solid $navForegroundColor;
        }
      }
    }
  }

  ul.mona-nav-list-main ul > li.active{
    position: relative;
    &:before{
      color: $primary !important;
      z-index: 3;
      top: 1px;
    }
    >a{
      position: relative;
      z-index: 2;
      color: $primary !important;
      .text-wrapper{
        position: relative;
        display: inline-block;
        border-radius: 2px;
        overflow: hidden;
        border: 5px solid $navForegroundColor;
        padding-right: 5px;
        margin-left: -30px;
        padding-left: 30px;
        &:before{
          position: absolute;
          z-index: -1;
          background: $navForegroundColor;
          width: 99%;
          height: 99%;
          content: "";
          margin-left: -30px;
          outline: 4px solid $navForegroundColor;
        }
      }
    }
  }

  .sublevel-trigger{
    position: absolute;
    left: 10px;
    top: 2px;
    height: 26px;
    width: 40px;
    background: transparent;
    z-index: 5;
  }
  .sublevel-trigger:after{
    content: "";
    position: absolute;
    left: 0px;
    top: -2px;
    height: 100%;
    width: 100%;
    background: transparent;
    z-index: 4;
    pointer-events: none;
  }

  .sublevel-trigger span{
    display: block;
    position: absolute;
    left: 4px;
    top: 4px;
    height: 20px;
    width: 20px;
  }

  .sublevel-trigger span:before{
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    left: 9px;
    top: 0px;
    background: $navForegroundColor;
    transition: all ease .2s;
    border-radius: 4px;
  }
  .sublevel-trigger span:after{
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    top: 9px;
    background: $navForegroundColor;
    border-radius: 4px;
  }

  .sublevel-trigger.open span:before{
    height: 1px;
    top: 9px;
    transition: all ease .2s;
  }

  hr{
    opacity: .8;
    border-top: 5px solid $navForegroundColor;
    margin: .7em math.div( $grid-gutter-width, 2) .5em math.div( $grid-gutter-width, 2);
  }

  li.meta{
    margin-top: 2em;
    h6{
      font-size: 17px;
      font-family: $font-family-sans-serif;
      font-weight: 600;
      color: $navForegroundColor;
      margin-left: 26px;
      padding-left: math.div( $grid-gutter-width, 2);
    }
    label{
      display: block;
      font-size: 12px;
      font-family: $font-family-sans-serif;
      font-weight: 500;
      color: $navForegroundColor;
      margin-left: 26px;
      padding-left: math.div( $grid-gutter-width, 2);
    }
    .text{
      display: block;
      color: $navForegroundColor;
      font-size: 14px;
      margin-left: 26px;
      padding-left: math.div( $grid-gutter-width, 2);
    }

    &.dimmed{
      h6{
        opacity: .8;
      }
    }

    hr{
      width: 40px;
    }

    a{
      opacity: .8;
      &:hover{
        opacity: 1;
      }
      margin-left: 26px;
      display: block;
      font-size: 12px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: math.div( $grid-gutter-width, 2);
    }
  }


}

@import "../fonts/local/fonts.css";
@import "../fonts/customicons/icons.css";

@import "bootstrap";
@import "bootstrap-dialog";
@import "custom";
@import "../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox";
@import "../../../node_modules/bxslider/dist/jquery.bxslider.min.css";
@import "../../../node_modules/ladda/css/ladda";
@import "../../../node_modules/blueimp-file-upload/css/jquery.fileupload.css";
@import "../../../node_modules/blueimp-file-upload/css/jquery.fileupload-ui.css";
@import "../../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "components/daterangepicker/daterangepicker_custom";
@import "components/abc_checkbox/awesome-bootstrap-checkbox.scss";
@import "components/abc_checkbox/_abc_checkbox_custom";
@import "components/nav_sidenav";
@import "components/nav_mobile";
@import "components/breadcrumb";
@import 'materialdesignicons/materialdesignicons';
//@import "plyr/plyr";


$hamburger-padding-x           : 12px;
$hamburger-padding-y           : 8px;
$hamburger-layer-width         : 15px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : $white;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $white;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;


@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

@import "components/_cookiebar";
@import "components/_figure";
@import "components/_accordion";


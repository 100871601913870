
@mixin image-followup-topspace-if-fullwidth{
  &+.tf,
  &+.year-list,
  &+.div,
  &+.p{
    margin-top: 2.2em;
  }
}


figure.image{

  padding-left: $grid-gutter-width*0.5;
  padding-right: $grid-gutter-width*0.5;

  picture{
    display: block;
  }

  .img-fluid{
    width: 100%;
  }

  .img-border{
    border: 1px solid #dee2e6 !important;
  }

  margin-top: 0.34em;
  &.float-left{
    padding-left: 0;
  }
  &.float-right{
    padding-right: 0;
  }
  a{
    display: block;
    &:before{
      display: none;
    }
  }

  &.col-12{
    @include media-breakpoint-only(xs) {
      @include image-followup-topspace-if-fullwidth;
    }
  }

  &.col-sm-12{
    @include media-breakpoint-only(sm) {
      @include image-followup-topspace-if-fullwidth;
    }
  }

  &.col-md-12{
    @include media-breakpoint-only(md) {
      @include image-followup-topspace-if-fullwidth;
    }
  }

  &.col-lg-12{
    @include media-breakpoint-only(lg) {
      @include image-followup-topspace-if-fullwidth;
    }
  }

  &.col-xl-12{
    @include media-breakpoint-only(xl) {
      @include image-followup-topspace-if-fullwidth;
    }
  }

  .i-wrap{
    &+.i-wrap{
      margin-top: 1em;
    }
  }

}

figure{
  display: inline-block;
  margin: 0 0 0.2rem 0;
  figcaption{
    background: $gray-300;
    display: block;
    font-family: $font-family-serif;
    font-size: 92%;
    color: $body-color;
    padding: $grid-gutter-width*0.25 $grid-gutter-width*0.5;
    *:first-child{
      margin-top: 0;
    }
    *:last-child{
      margin-bottom: 0;
    }
    a{
      display: inline-block !important;
    }
  }
}


//figure.supersize-xs,
//figure.supersize-sm,
//figure.supersize-md,
//figure.supersize-lg,
//figure.supersize-xl{
//  &.float-left,
//  &.float-right{
//    float: none !important;
//  }
//
//}

@include media-breakpoint-up(xl) {
  figure.supersize-xl{

    display: block;
    float: none !important;
    padding: 0;
    figcaption,
    >div.i-wrap{
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  figure.col-xl-12{
    &.float-left,
    &.float-right{
      float: none !important;
      padding: 0;
    }
  }
}
@include media-breakpoint-only(lg) {
  figure.supersize-lg{
    float: none !important;
    padding: 0;
    figcaption,
    >div.i-wrap{
      margin-left: -15px;
      margin-right: -15px;
    }

  }
  figure.col-lg-12{
    &.float-left,
    &.float-right{
      float: none !important;
      padding: 0;
    }
  }
}
@include media-breakpoint-only(md) {
  figure.supersize-md{
    float: none !important;
    padding: 0;
    figcaption,
    >div.i-wrap{
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  figure.col-md-12{
    &.float-left,
    &.float-right{
      float: none !important;
      padding: 0;
    }
  }
}
@include media-breakpoint-only(sm) {

  figure.supersize-sm{
    float: none !important;
    padding: 0;
    figcaption,
    >div.i-wrap{
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  figure.col-sm-12{
    &.float-left,
    &.float-right{
      float: none !important;
      padding: 0;
    }
  }
}
@include media-breakpoint-only(xs) {
  figure.supersize-xs{
    float: none !important;
    padding: 0;
    figcaption,
    >div.i-wrap{
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  figure.col-12{
    border: 4px solid lime;
    &.float-left,
    &.float-right{
      float: none !important;
      padding: 0;
    }
  }
}


body, html{
  height: 100%;
  min-height: 100%;
}

.btn-xs{
  @extend .btn-sm;
  $scaleXS: 0.85;
  padding-left: $btn-padding-x-sm * $scaleXS;
  padding-right: $btn-padding-x-sm * $scaleXS;
  padding-top: $btn-padding-y-sm * $scaleXS;
  padding-bottom: $btn-padding-y-sm * $scaleXS;
  font-size: $btn-font-size-sm * $scaleXS;
}

@mixin layout-text-underline {
  background-image: linear-gradient(transparent 0%, transparent .75em, $underline-color .75em, $underline-color 1.18em, transparent 1.18em, transparent);
  display: inline;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    background-image: linear-gradient(transparent 0em, transparent .49em, $underline-color .49em, $underline-color .96em, transparent .96em, transparent);
  }
  @supports (-ms-ime-align:auto) {
    // IE Edge
    .selector { property:value; }
    background-image: linear-gradient(transparent 0em, transparent .49em, $underline-color .49em, $underline-color .92em, transparent .92em, transparent);
  }
}

u{
  text-decoration: none;
  @include layout-text-underline;
  font-weight: 400;
}

header{
  .navbar-brand{
    @include rfs(1.2rem);
    @include media-breakpoint-down(sm) {
      font-size: calc(0.125rem + 3.3vw);
    }
    .brand-name{
      font-size: 1.3em;
      font-weight: 400;
    }
    .brand-claim{
      font-size: 1em;
      font-weight: 400;
    }
  }
  .user-account-button{
    color: $secondary;
    background-color: $white;
    i.mdi{
      line-height: 1;
    }
    padding: .2em .4em;
    &[aria-expanded="true"]
    {
      background-color: $white;
      color: $secondary;
    }
  }
}

footer{
  hr{
    border-top-width: 0;
    opacity: 1;
    height: 6px !important;
    width: 40px;
    background: $gray-500;
      margin-left: -15px;
    margin-right: auto;
    margin-top: 1.4em;
    @include media-breakpoint-down(lg) {
      margin-left: auto;
    }
  }
  a{
    color: $secondary;
  }
}

.border-radius{
  border-radius: $border-radius;
}
.border-radius-top-0{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.border-radius-bottom-0{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tf{
  *:last-child{
    margin-bottom: 0;
  }
}

.p-all-lead{
  p{
    @extend .lead;
  }
}

.fancybox-container{
  .fancybox-content{
    width: 900px;
    max-width: 900px;
    .col.col-width-limited{
      width: 100% !important;
    }
  }
}

.btn{
  font-weight: bold;
  small, .small{
    font-size: .6875em;
  }
  &.btn-primary{
    .text-muted{
     color: $white !important;
      opacity: .8;
    }
  }
  &.btn-hold-to-confirm {
    .progress {
      transition: all ease .3s;
      opacity: 0;
      height: 0;
      margin-top: 0;
      background-color: transparent;
      .progress-bar{
        height: 100%;
        width: 0;
        transition-property: width;
        transition-timing-function: ease;
        transition-duration: 500ms;
      }
    }
    &.animating{
      .progress {
        transition: all ease .3s;
        height: .3em;
        margin-top: .8em;
        opacity: 1;
        .progress-bar{
          width: 100%;
          transition-property: width;
          transition-timing-function: linear;
          transition-duration: 10s;
        }
      }
    }
  }
}

.status-wrapper{
  position: relative;
}

.tempsearch-items-list, .tempsearch-application-modal{
  padding-top: 1em;
  margin: 0 -.75em;
  .tempsearch-item{
    position: relative;
    &+.shift-item{
      margin-top: .6em;
    }
    background-color: $brand-gray-light;

    .btn{
      font-weight: 500;
    }

    .badge{
      @extend .h6;
      @extend .py-2;
      @extend .px-3;
      margin: 0;
      text-align: left;
    }
    .badge-w-100{
      .badge{
        width: 100%;
      }
    }

    &.status-not-qualified{
      *{
        cursor: default;
      }
      .status-label-text{
        font-weight: bold;
        color: $gray-700;
      }
      .op-low-if-covered{
        opacity: .2;
      }
      background-color: $gray-200;
    }

    &.status-pending_request{
      *{
        cursor: default;
      }
      .status-label-text{
        font-weight: bold;
        color: $orange;
      }
      .op-low-if-covered{
        opacity: .2;
      }
      background-color: $gray-200;
    }

    &.status-covered{
      *{
        cursor: default;
      }
      .status-label-text{
        font-weight: bold;
        color: $danger;
      }
      .op-low-if-covered{
        opacity: .2;
      }
      background-color: $gray-200;

    }
    &.status-open{
      .status-label-text{
        font-weight: bold;
        color: $success;
      }
      &:hover{
        background-color: $brand-nav-highlight;
      }
    }

  }

}

.bootstrap-dialog{
  .modal-content{
    background: none;
    .modal-header{
      border-radius: 0;
      .bootstrap-dialog-title{
        @extend .h4;
        margin: 0;
      }
    }
    .modal-body{
      background-color: $white;
    }
  }
}

.tempsearch-application-modal{
  label{
    display: block;
    margin-bottom: .2em;
    font-weight: bold;
    color: $gray-800;
  }
}

.form-floating{
  .bootstrap-select{
    .dropdown-toggle{
      height: calc(3.5rem + 2px);
      padding: 1rem 0.75rem;
      &:focus{
        outline: none !important;
        outline: none !important;
      }
      &.bs-placeholder{
        color: rgba(255,255,255,0) !important;
      }
      &:after{
        border-top-color: $body-color;
        margin-top: 2px;
      }
      &.show{
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: $input-focus-box-shadow;
        }
      }
    }
    .dropdown-menu{
      a:focus, a:active, a:hover{
        outline: none !important;
      }
    }
    &.has-value{
      .dropdown-toggle{
        padding-top: 1.625rem;
        padding-bottom: 0;
        &:after{
          margin-top: calc((1.75rem - 3px) * -1);
        }
      }
      ~label {
        opacity: .65;
        transform: scale(.85)translateY(-0.5rem)translate(0.15rem);
      }
    }
  }
}

.has-danger{
  .fv-plugins-message-container{
    color: $danger;
    font-weight: 400;
  }
}
.passwordFeedbackProgress{
  margin-top: 0.55em;
  .progress{
    height: .3em;
    background-color: transparent;
    .progress-bar{
      //background-color: $primary !important;
    }
  }
}
.passwordFeedbackText{
  > span{
    color: $danger;
    &.text-warning{
      color: $success !important;
    }
  }
}


.dropdown-menu li {
  position: relative;
  .dropdown-item{
    i.mdi{
      font-size: 90%;
      position: absolute;
      top: 0.16em;
      left: -0.999em;
    }
  }
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  right: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  right: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.main-form-error-out{
  .timed-alert-message{
    margin-top: 1em;
    margin-bottom: 0;
    .progress{
      margin-top: .8em;
      height: 3px;
    }
  }
}

.work-timer-module{

  .bg-pause, .btn-pause{
    background-color: $warning;
  }
  .bg-pause-light{
    background-color: rgba($warning, .2);
  }
  .bg-work, .btn-work{
    background-color: $danger;
  }
  .bg-work-light{
    background-color: rgba($danger, .2);
  }

  .btn-pause{
    &:hover{
      background-color: lighten($warning, 6%);

    }
  }
  .btn-work{
    &:hover{
      background-color: lighten($danger, 8%);
    }
  }



  .work-timer-action{

    &.btn-hold-to-confirm{
      position: relative;
        .progress-wrapper{
          pointer-events: none;
          position: absolute;
          bottom: .2em;
          padding: 0 .4em 0 .4em;
          width: 100%;
          left: 0;
        }
    }

    &+.work-timer-action{
      margin-left: 1em;
    }
  }

}

.work-timer-detail-item{

  .border-wrapper{
    position: relative;
    z-index: 1;
    margin-top: -.15em;
    margin-bottom: -.15em;
    border-left-width: 0 !important;
  }
  .item-state-marker{
    width: .33em;
  }
  &.state-working{
    .item-state-marker{
      background-color: $danger;
    }
  }
  &.state-pause{
    .item-state-marker{
      background-color: $warning;
    }
  }

  .btn-add-work{
    color: $white;
  }

  .btn-add-work-before{
    position: relative;
    z-index: 2;
  }
  .btn-add-work-after{
    position: relative;
    z-index: 2;
    top: .3em;
    margin-top: -1em;
  }

}

.work-timer-list-item{
  $barHeight: .4em;
  position: relative;
  z-index: 2;
  .timer-bar{
    position: relative;
    height: $barHeight;
    .segment-item{
      position: absolute;
      width: 0;
      min-width: 3px;
      height: $barHeight;
      background-color: gray;
      top: 0;
      left: 0;
      margin-left: -1px;
      border-left: 1px solid white;
      border-right: 1px solid white;
      &.state-working{
        background-color: $danger;
        &.hover-state{
          outline: 2px solid rgba($danger, .5);
          //z-index: 10001 !important;
        }
      }
      &.state-pause{
        background-color: $warning;
        &.hover-state{
          outline: 2px solid rgba($warning, .5);
          //z-index: 10001 !important;
        }
      }
      &.is-open-end{
        &:after{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          animation: blinker 1s linear infinite;
        }
      }
    }
  }
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  78% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
[data-trigger-click-on-input]{
  cursor: pointer;
  input{
    cursor: pointer!important;
    &.rangepicker-is-shown{
      cursor: text!important;
    }
  }
}

@use "sass:math";

aside.side-nav{
  a{
    text-decoration: none;
  }
  .list-group{
    .list-group-item{
      background: $brand-gray-light;
      border-color: $brand-gray-light;
      font-weight: 500;
      border-top-color: $white;
      border-top-width: 2px !important;
      a{
        font-weight: 500;
        color: $secondary;
      }
      &.active{
        //border-color: $brand-gray-light;
        background-color: $brand-nav-highlight;
        color: $primary;
        >a{
          color: $primary;
        }
      }
      .disabled{
        color: $gray-500;
      }
    }
    ul{
      padding-left: 0;
      font-size: 94%;
      margin: .2em 0;
      > .list-group-item{
        border: none;
        background-color: transparent;
        padding-top: .25rem;
        padding-bottom: .25rem;
        &.active{
          background-color: transparent;
          color: $primary;
          > a {
            color: $primary;
          }
          > ul{
            font-size: 92%;
            > .list-group-item{
              &.active {
                color: $primary;
                background-color: transparent;
                > a {
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }
  }

  .login-info{
    background-color: $brand-nav-highlight;
    color: $secondary !important;
    padding: math.div( $grid-gutter-width, 2);
    h5{
      color: $secondary !important;
      margin: 0 !important;
    }
    h6{
      color: $secondary !important;
      margin: 0 !important;
    }
    hr{
      opacity: 1;
      border-top-color: $secondary;
      border-top-width: 2px;
      margin: .4em 0;
    }
    label{
      font-size: 80%;
      font-weight: 500;
      display: block;
    }
    margin-bottom: .8em;
  }

}

nav{
  ol.breadcrumb{
    li{
      a{
        color: $breadcrumb-divider-color;
        text-decoration: none;
        font-weight: 400;
      }
    }

    .breadcrumb-item+.breadcrumb-item:before{
      position: relative;
      top: -.09em;
    }

  }
}

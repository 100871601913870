.bootstrap-dialog {
  /* dialog types */
  /**
   * Icon animation
   * Copied from font-awesome: http://fontawesome.io/
   **/
  /** End of icon animation **/
}

$brand-primary: $primary;
$brand-secondary: $secondary;
$brand-success: $success;
$brand-info: $info;
$brand-warning: $warning;
$brand-danger: $danger;
$brand-light: $light;
$brand-dark: $dark;


.bootstrap-dialog .modal-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bootstrap-dialog .bootstrap-dialog-title {
  color: #fff;
  display: inline-block;
  font-size: 16px;
}

//.bootstrap-dialog .bootstrap-dialog-message {
//  font-size: 14px;
//}

.bootstrap-dialog .bootstrap-dialog-button-icon {
  margin-right: 3px;
}

.bootstrap-dialog .bootstrap-dialog-close-button {
  float: right;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.bootstrap-dialog .bootstrap-dialog-close-button:hover {
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=100);
}

@media (min-width: 1172px) {
  .bootstrap-dialog .modal-xl {
    max-width: 95%;
  }
}

.bootstrap-dialog .modal-lg .bootstrap4-dialog-button:first-child {
  margin-top: 8px;
}

.bootstrap-dialog.type-default .modal-header {
  background-color: #fff;
}

.bootstrap-dialog.type-default .bootstrap-dialog-title {
  color: #333;
}

.bootstrap-dialog.type-info .modal-header {
  background-color: $brand-info;
}

.bootstrap-dialog.type-primary .modal-header {
  background-color: $brand-primary;
}

.bootstrap-dialog.type-secondary .modal-header {
  background-color: $brand-secondary;
}

.bootstrap-dialog.type-success .modal-header {
  background-color: $brand-success;
}

.bootstrap-dialog.type-warning .modal-header {
  background-color: $brand-warning;
}

.bootstrap-dialog.type-danger .modal-header {
  background-color: $brand-danger;
}

.bootstrap-dialog.type-light .modal-header {
  background-color: $brand-light;
}

.bootstrap-dialog.type-dark .modal-header {
  background-color: #343a40;
}

.bootstrap-dialog.size-large .bootstrap-dialog-title {
  font-size: 24px;
}

.bootstrap-dialog.size-large .bootstrap-dialog-close-button {
  font-size: 30px;
}

.bootstrap-dialog.size-large .bootstrap-dialog-message {
  font-size: 18px;
}

.bootstrap-dialog .icon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.bootstrap-dialog-footer-buttons{
  display: flex;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.bootstrap-dialog-header {
  display: contents;
}



/* Generated by grunt-webfont */



@font-face {
	font-family:"icons";
	src:url("font/icons.woff?ece6f1cfc1d6ee0dadd66934dc990dce") format("woff"),
		url("font/icons.ttf?ece6f1cfc1d6ee0dadd66934dc990dce") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.ci {
	
		font-family:"icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.ci-check-decagram-outline:before {
	content:"\f101";
}


.ci-double-angle-up:before {
	content:"\f102";
}


.ci-double-angle:before {
	content:"\f103";
}


.ci-ds81:before {
	content:"\f104";
}


.ci-email-fast:before {
	content:"\f105";
}


.ci-facebook:before {
	content:"\f106";
}


.ci-format-list-text:before {
	content:"\f107";
}


.ci-instagram:before {
	content:"\f108";
}


.ci-lang-change:before {
	content:"\f109";
}


.ci-message-outline:before {
	content:"\f10a";
}


.ci-nav-arrow-left:before {
	content:"\f10b";
}


.ci-nav-arrow:before {
	content:"\f10c";
}


.ci-paperclip:before {
	content:"\f10d";
}


.ci-trash-can:before {
	content:"\f10e";
}


.ci-upload-multiple:before {
	content:"\f10f";
}


.ci-upload:before {
	content:"\f110";
}


.ci-web:before {
	content:"\f111";
}


.ci-youtube:before {
	content:"\f112";
}

@use "sass:math";

#dscookiebar_cover{
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 110%;
  width: 100%;
  height: 100%;
  z-index: 1089;
  transition: opacity .3s ease, left 1ms ease .3s;
}
.dscookiebar-show-cover{
  body{
    overflow-y: hidden;
  }
  #dscookiebar_cover{
    left: 0;
    opacity: 1;
    transition: opacity .3s ease, left .01s ease 1ms;
  }
  #dscookiebar_consent{
    .inner{
      border-top: none;
    }
  }
}

#dscookiebar_consent{

  position: fixed;
  //bottom: 1vw;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1090;
  //@include media-breakpoint-down(md) {
  //  bottom: 2vw;
  //}
  //@include media-breakpoint-down(sm) {
  //  bottom: 2vw;
  //}
  //@include media-breakpoint-down(xs) {
  //  bottom: 3vw;
  //}

  .container{
    position: relative;
    //max-width: 1680px;
  }
  //@media only screen and (max-width: 1680px) {
  //  .container{
  //    max-width: 100%;
  //  }
  //}

  .inner{
    border-radius: 0;
    //border: 15px solid $white;
    border-top: 10px solid $white;
    margin-top: 5px;
    //@include media-breakpoint-down(xs) {
    //  border-left: none;
    //  border-right: none;
    //}
    width: 460px;
    background: $brand-black;
    //color: $white;
    padding: 1.1em .99em;
    max-height: 96vh;


    h1, h2, h3, h4, h5, h6{
      color: $white;
    }

    &.tf{
      p{
        color: $white;
        line-height: 1.33;
        a{
          color: $white;
        }
      }
    }

    position: absolute;
    bottom: 0;
    //left: math.div($grid-gutter-width, 2);
    @include media-breakpoint-down(xs) {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
    }

    //@media only screen and (min-width: 1681px) {
    //  margin: 0 1vw;
    //}


    a{
      color: $white;
    }
    *:first-child{
      margin-top: 0;
    }
    *:last-child{
      margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6{
      //color: $white;
      font-weight: 500;
    }

    .spacer-wrapper{
      display: block;
    }
    @media only screen and (min-width: 423px) {
      .spacer-wrapper{
        display: inline-block;
        width: 1.3em;
      }
    }

    .btn{
      font-weight: 600;
      border-color: $white;
      @media only screen and (max-width: 422px) {
        margin-bottom: 1em;
        &:last-child{
          margin-bottom: 0em;
          &:after{
            display: none;
          }
        }
      }
      &:hover{
        background: rgba(255,255,255,0.9);
      }
      cursor: pointer;
      &.btn-outline-white{
        border-color: $white;
        background: none;
        color: $white;
        &:hover{
          background: rgba(255,255,255,0.2);
        }
      }
    }
  }

  .abc-checkbox{
    position: relative;
    input[type="checkbox"],
    input[type="radio"] {
      position: absolute!important;
      width: 100%;
      height: 100%;
      &:focus + .form-check-label::before{
        outline: none !important;
        box-shadow: none;
        border-color: $white !important;
      }
      &:checked{
        &+.form-check-label {
          &::before {
            background-color: $white !important;
            border-color: $white !important;
          }
          &::after{
            //color: $secondary !important;
          }
        }
      }
      &:disabled{
        &::before {
          border-color: $white !important;
        }
        &+.form-check-label{
          cursor: not-allowed;
          opacity: .75;
          color: $white;
        }
      }
    }

    .form-check-label {

      .label-text{
        top: -2px;
      }
      &::before {
        border-color: $white;
      }
      &::after{
        //color: $secondary !important;
        top: -1px !important;
        left: -12px !important;
      }
    }
  }

  .consent-item-list{
    margin-bottom: 1em;
    .cookie-desc{
      display: block;
      //font-size: 88%;
      >*{
        line-height: 1.33;
      }
      margin-top: .3em !important;
    }
    .cookie-realname{
      color: $white;
      font-weight: 500;
      display: block;
      //font-size: 95%;
      >*{
        font-weight: 600;
        line-height: 1.43;
      }
    }
    .form-group-checkbox{
      margin-bottom: .9rem;
    }
  }

}

#dscookiebar_onpage_consent{
  .abc-checkbox{
    position: relative;
    input[type="checkbox"],
    input[type="radio"] {
      position: absolute!important;
      width: 100%;
      height: 100%;
      &:disabled{
        &+.form-check-label{
          cursor: not-allowed;
          opacity: .70;
          color: $body-color;
        }
      }
    }
  }
}

.consent-item-list-onpage{

  .cookie-desc{
    display: block;
    font-size: 92%;
    margin-top: .3em !important;

    >*{
      line-height: 1.33;
    }
  }

  .label-text{
    top: -2px !important;
  }

  .cookie-realname{
    display: block;
    //font-size: 95%;
    >*{
      font-weight: 600;
      line-height: 1.43;
    }
  }
  .form-group-checkbox{
    margin-bottom: 1.5rem;
  }
  .btn-save-consent-settings{
    font-weight: 600;
  }

  .message-all-up-to-date{
    font-weight: 500;
  }

  .abc-checkbox .form-check-label:before{
    border-width: 2px;
    border-color: $secondary;
  }

}


//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'FontAwesome' !default;
$fa-var-check: "\f00c" !default;
$check-icon: $fa-var-check !default;

@mixin checkbox-variant($parent, $color) {
  #{$parent} input[type="checkbox"]:checked + .form-check-label,
  #{$parent} input[type="radio"]:checked + .form-check-label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      color: #fff;
    }
  }
}

@mixin checkbox-variant-indeterminate($parent, $color) {
  #{$parent} input[type="checkbox"]:indeterminate + .form-check-label,
  #{$parent} input[type="radio"]:indeterminate + .form-check-label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      background-color: #fff;
    }
  }
}

.abc-checkbox{
  cursor: default;
  padding-left: 4px;

  .form-check-label{
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -$form-check-input-gutter;
      border: 1px solid $input-border-color;
      border-radius: 3px;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 2px;
      margin-left: -$form-check-input-gutter;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: $input-color;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + .form-check-label::before{
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked + .form-check-label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + .form-check-label::after{
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled + .form-check-label{
      opacity: 0.65;

      &::before{
        background-color: $input-disabled-bg;
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.abc-checkbox-circle .form-check-label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

@include checkbox-variant('.abc-checkbox-primary', theme-color(primary));
@include checkbox-variant('.abc-checkbox-danger', theme-color(danger));
@include checkbox-variant('.abc-checkbox-info', theme-color(info));
@include checkbox-variant('.abc-checkbox-warning', theme-color(warning));
@include checkbox-variant('.abc-checkbox-success', theme-color(success));


@include checkbox-variant-indeterminate('.abc-checkbox-primary', theme-color(primary));
@include checkbox-variant-indeterminate('.abc-checkbox-danger', theme-color(danger));
@include checkbox-variant-indeterminate('.abc-checkbox-info', theme-color(info));
@include checkbox-variant-indeterminate('.abc-checkbox-warning', theme-color(warning));
@include checkbox-variant-indeterminate('.abc-checkbox-success', theme-color(success));

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"]{
    + .form-check-label{
      &::after{
        background-color: $color;
      }
    }
    &:checked + .form-check-label{
      &::before {
        border-color: $color;
      }
      &::after{
        background-color: $color;
      }
    }
  }
}

.abc-radio{
  cursor: default;
  padding-left: 4px;

  .form-check-label{
    cursor: pointer;
    display: inline;
    vertical-align: top;
    position: relative;
    padding-left: 5px;

    &::before{
      cursor: pointer;
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 2px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $input-border-color;
      border-radius: 50%;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      cursor: pointer;
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 5px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $input-color;
      transform: scale(0, 0);

      transition: transform .1s cubic-bezier(.8,-0.33,.2,1.33);
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type="radio"]{
    position: static;
    margin-left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:focus + .form-check-label::before{
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked + .form-check-label::after{
      transform: scale(1, 1);
    }

    &:disabled + .form-check-label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}

@include radio-variant('.abc-radio-primary', theme-color(primary));
@include radio-variant('.abc-radio-danger', theme-color(danger));
@include radio-variant('.abc-radio-info', theme-color(info));
@include radio-variant('.abc-radio-warning', theme-color(warning));
@include radio-variant('.abc-radio-success', theme-color(success));


// Validation states
// TODO: keep in sync with bootstrap since strange comment 'redo check markup lol crap'
.form-check-input {
  .was-validated &:invalid,
  &.is-invalid {
    .abc-checkbox,
    .abc-radio {
      .form-check-label &:before {
        border-color: $form-feedback-invalid-color;
      }
    }
  }
}

@use "sass:math";

$header-bg-closed: $secondary;
$header-bg-opened: $primary;
$header-color-closed: $white;
$header-color-opened: $white;


.accordion {
  margin: 1.5em 0 1.5rem 0;

  .accordion-item {
    border: none;
    & + .accordion-item {
      margin-top: .8em;
    }


    .box {


      .box-inner {

        .accordion-header {
          cursor: pointer;
          color: $header-color-opened;
          background-color: $header-bg-closed;
          position: relative;
          font-size: 18px;
          padding:  (math.div( $grid-gutter-width, 2) + .03) math.div( $grid-gutter-width, 2) math.div( $grid-gutter-width, 2) (math.div( $grid-gutter-width, 1) + .2);

          margin-bottom: 3px;


          &:before{
            content: "›";
            position: absolute;
            left: 10px;
            top: 2px;
            color: $white;
            font-size: 30px;
            font-weight: $font-weight-normal;
            transition: all ease .2s;
          }

          &[aria-expanded="true"]{
            &:before{
              top: 5px;
              left: 14px;
              content: "›";
              transform-origin: center;
              transform: rotate(90deg);
              transition: all ease .2s;
            }
          }

        }

        .accordion-body {
          padding:  math.div( $grid-gutter-width, 2);
          background: $gray-300;

          .tf{

            *::-moz-selection { background: rgba($white, .45)}
            *::selection { background: rgba($white, .45); }

            h1, h2, h3, h4, h5, h6{
              color: $body-color;
            }
          }

        }

        .accordion-collapse {
          border: none;
        }

      }

    }
  }
}

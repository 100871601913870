
.abc-radio{

  input[type="checkbox"],
  input[type="radio"] {
    &:focus + .form-check-label::before{
      outline: none !important;
      box-shadow: none;
      //border-color: $body-color;
    }
    &:checked{
      &+ .form-check-label {
        &::before {
          background-color: $primary;
          border-color: $primary;
        }
        &::after {
          background-color: $secondary;
          //font-family: "Material Design Icons";
          //content: "\f12c";
          //left: -3px;
        }
      }
    }
  }

  .form-check-label {
    padding-left: 12px;
    display: inline-block;
    margin-left: 0;
    width: 95%;
    .label-text{
      display: inline-block;
      width: 100%;
      position: relative;
      top: -1px;
      .text-indent{
        padding-top: 0.08em;
        display: inline-block;
        width: 100%;
        position: relative;
        @include media-breakpoint-down(lg){
          padding-top: 1px;
        }
        @include media-breakpoint-down(sm){
          top: 0;
        }
        @include media-breakpoint-down(xs){
          top: 0;
        }
        *:last-child{
          margin-bottom: 0;
        }
      }
    }
    &::before {
      top: 3px;
      left: -2px;
      border-width: 2px;
      //border-radius: $border-radius;
      width: 21px;
      height: 21px;
      border-color: $body-color;
      @include media-breakpoint-up(xl){
        top: 3px;
      }
      @include media-breakpoint-down(lg){
        top: 2px;
      }
    }
    &::after{
      top: 8px;
      font-size: 14px;
      color: $white;
    }

    //line-height: 1.333;

    small{
      display: block;
      padding-top: 0.2em;
    }

  }
  &.form-check-inline{
    .form-check-label {
      &::before {
        top: 3px;
      }
      &::after {
        top: 3px;
      }
    }
  }
}

.abc-checkbox{

  //padding-left: 14px;

  //@media only screen and (max-width: 428px) {
  padding-left: 30px;
  //}

  .form-check-input{
    position: absolute !important;
  }

  input[type="checkbox"],
  input[type="radio"] {
    &:focus + .form-check-label::before{
      outline: none !important;
      box-shadow: none;
      border-color: $body-color;
    }
    &:checked{
      &+ .form-check-label {
        &::before {
          //background-color: transparent;
          //background-color: $primary;
          //border-color: $secondary;
        }
        &::after {
          //color: transparent !important;
          //font-family: "Material Design Icons";
          //content: "\F012C";
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTIxLDdMOSwxOUwzLjUsMTMuNUw0LjkxLDEyLjA5TDksMTYuMTdMMTkuNTksNS41OUwyMSw3WiIgLz48L3N2Zz4=");
          width: 18px;
          height: 18px;
          background-size: contain;
          left: -9px!important;
          top: 3px!important;
        }
      }
    }
  }

  .form-check-label {
    padding-left: 5px;
    display: inline-block;
    margin-left: 0;
    width: 95%;
    .label-text{
      display: inline-block;
      width: 100%;
      position: relative;
      //top: -3px;
      top: -0.1em;
      .text-indent{
        padding-top: 0.08em;
        display: inline-block;
        width: 100%;
        position: relative;
        @include media-breakpoint-down(lg){
          padding-top: 1px;
        }
        @include media-breakpoint-down(sm){
          top: 0;
        }
        @include media-breakpoint-down(xs){
          top: 0;
        }
        *:last-child{
          margin-bottom: 0;
        }
      }
    }
    &::before {
      top: 1px;
      left: -10px;
      border-radius: 0;
      width: 21px;
      height: 21px;
      border-color: $body-color;
      border-width: 2px;
      @include media-breakpoint-up(xl){
        //top: 3px;
      }
      @include media-breakpoint-down(lg){
        //top: 2px;
      }
    }
    &::after{
      top: -1px;
      left: -12px!important;
      font-size: 1.3rem;
      color: transparent;
      @include media-breakpoint-up(xl){
        //top: 0px;
      }
      @include media-breakpoint-down(lg){
        //top: -1px;
      }
    }

    //line-height: 1.333;

    small{
      display: block;
      padding-top: 0.2em;
    }

  }
  &.form-check-inline{
    .form-check-label {
      &::before {
        top: 3px;
      }
      &::after {
        top: 3px;
      }
    }
  }
}
